<template>
  <TeleportSlot />
</template>

<script setup lang="ts">
import {
  computed,
  h,
  inject,
  onMounted,
  ref,
  Teleport,
  useSlots,
  useAttrs,
  watch,
} from 'vue';
import { PROVIDE_TELEPORT_TARGETS, TeleportTargets } from './const';
import { usePresence } from '../usePresence';
import type { PropType } from 'vue';

const props = defineProps({
  to: {
    type: [String, Object] as PropType<string | HTMLElement>,
    required: true,
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  force: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

const targets = inject<TeleportTargets>(PROVIDE_TELEPORT_TARGETS);
const { inDeck, slideId, scope } = usePresence();

const inScope = computed(() => scope.value === slideId.value);

function isReady() {
  return (
    (typeof props.to === 'string' &&
      (targets?.[props.to] != null ||
        (typeof document !== 'undefined' &&
          !!document.querySelector(props.to)))) ||
    (typeof props.to === 'object' && !!props.to)
  );
}

const ready = ref<boolean>(isReady());

onMounted(() => {
  ready.value = isReady();

  watch(scope, () => {
    ready.value = (ready.value && scope.value) as boolean;
  });

  if (targets && typeof props.to === 'string') {
    watch(
      () => targets[props.to as string],
      value => {
        ready.value = value != null;
      },
    );
  }
});

const disabledFromTarget = computed(
  () => typeof props.to === 'string' && targets?.[props.to] === true,
);

const attrs = useAttrs();
const slots = useSlots();
const TeleportSlot = () =>
  ready.value && (inScope.value || props.force)
    ? h(
        Teleport,
        {
          to: props.to,
          disabled:
            props.disabled ||
            disabledFromTarget.value ||
            (!inDeck.value && !props.force),
          ...attrs,
        },
        {
          default: () => slots.default?.(),
        },
      )
    : h('div', {}, [slots.default?.()]);
</script>
