import { inject, onMounted, ref } from 'vue';
import { createEventHook } from '@vueuse/core';
import { useInteractions, useReveal } from '@/apps/slideshow/useReveal';

const onPauseStub = createEventHook();
const onResumeStub = createEventHook();
const onSlideStub = createEventHook();

export function usePresence() {
  const inDeck = inject('inDeck', ref(false));
  const scope = inject('scope', ref(''));

  if (inDeck.value) {
    const { onPause, onResume, onSlide, onReady, context } = useReveal();
    const slideId = ref<string>('');

    onMounted(() => {
      if (context.value) {
        slideId.value = context.value.getId();
      }
    });

    onReady(({ context }) => {
      slideId.value = context.getId();
    });
    onSlide(({ context }) => {
      slideId.value = context.getId();
    });

    return {
      onPause,
      onResume,
      onSlide,
      inDeck,
      slideId,
      scope,
    };
  }

  return {
    onPause: onPauseStub.on,
    onResume: onResumeStub.on,
    onSlide: onSlideStub.on,
    inDeck,
    slideId: ref(Math.random().toString(36).slice(2, 7)),
    scope,
  };
}

export function usePresenceControl() {
  const inDeck = inject('inDeck', ref(false));

  if (inDeck.value) {
    const { onPause, onResume } = useInteractions();
    return {
      onPause,
      onResume,
      inDeck,
    };
  }

  return {
    inDeck,
    onPause: onPauseStub,
    onResume: onResumeStub,
  };
}
